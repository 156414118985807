<template>
  <div class="home-block">
    <div class="home">
      <!-- 头部开始 -->
      <div class="home-header">
        <img class="w100f" src="../../assets/images/hospital_business_bg.jpg" alt="" />
        <!-- <div class="bg-text">
          <div class="bg-text-left">
            <p class="font-title">经典案例</p>
          </div>
        </div> -->
      </div>
      <!-- 头部结束 -->
      <!-- 中间内容开始 -->
      <div class="main-contain">
        <div class="h24"></div>
        <!-- <Tab :tabs="tabs" @tabChange="onTabChange" ref="tabs"> </Tab> -->
        <div class="case-title">传世般若SPD合作伙伴招商书</div>
        <!-- <img class="w100f" src="../../assets/images/case_spd1.png" alt="" /> -->
        <!-- <div class="case-title">客户简介</div> -->
        <p>
            深圳传世般若科技有限公司(简称传世科技)是国家高新技术企业，定位医药行业流通数字化解决方案服务商，以供应链数字技术为基础，为医疗机构及医药企业提供软硬件一体化解决方案，在武汉、北京、威海、西安分别设有分支机构。
        </p>
        <p>传世科技总经理蘧勇先生，自二十一世纪初就在国外从事医药物流行业，是将国外SPD整体经验与技术系统性引进国内的先行者，在国内多家头部医药公司组织落地了多个成功SPD应用案例；现今联合创办了传世科技，在潜心总结了以往经验之后，重新打造了拥有全部知识产权且更加卓越的SPD全套解决方案和全系列产品，作品著作权包括“传世医院物流整体解决方案”的 (国作登字-2019-I-00817442)；软件著作权包括医院药品SPD、医用物资SPD、医链云(供应商采购平台)等，产品专利包括超高频RFID高值耗材柜、普耗柜、试剂存放柜、配送机器人等系列。</p>
        <p>
            当前国内SPD发展速度较快，根据2021年全国医院SPD项目公开招标不完全汇总显示，2021年全年共有26个省市211家医院公开招标SPD相关项目，《2020年度中国医院综合排行榜》中的百强医院已有53%实施了SPD。在头部医院的带动效应之下，实施SPD已成为智慧化医院数字化转型的基本需求，市场正处于快速发展期。
        </p>
        <p>传世科技诚招全国各地SPD合作伙伴，传世科技愿为合作伙伴提供包括产品开源在内的一站式解决方案，帮助合作伙伴成功销售。</p>
        <!-- <p>招商洽谈请联系: </p> -->
        <p>招商洽谈请联系叶先生：188-0278-9381(微信同号) </p>
        <!-- <p></p> -->
        <div class="h100"></div>
      </div>
    </div>
    <mobilePage></mobilePage>
  </div>
    
</template>

<script>
import mobilePage from '@/views/hospital/mobile/hospital_business.vue'; //移动端页面
import Tab from '@/components/tab/tab.vue'; //选项卡组件
import Title from '@/components/title/title.vue'; //标题组件
export default {
  name: 'home',
  components: {
    Tab,
    Title,
    mobilePage
  },
  data() {
    return {
      tabs: [
        { title: 'FBT案例', path: '/case/fbt' },
        { title: 'SPD案例', path: '/case/spd' },
        { title: '高值耗材柜案例', path: '/case/cabinet' },
        { title: '药链云案例', path: '/case/yly' },
      ],
    };
  },
//   mounted(){
//     this.$nextTick(()=>{
//       this.$refs.tabs.currentIndex = 1
//     })
//   },
  methods: {
    onTabChange(index) {
      this.currentPageIndex = index;
      console.log(index);
    },
  },
};
</script>
<style lang="less" scoped>
.home {
  //头部
  .home-header {
    position: relative;
    width: 100%;
    .bg-text {
      width: 1200px;
      // height: 200px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: absolute;
      top: 42%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      .bg-text-left {
        text-align: left;
        .font-title {
          font-size: 40px;
          color: #3d3d3d;
        }
        .font-title-txt {
          font-size: 18px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          line-height: 42px;
          color: #3d3d3d;
        }
        .next-btn {
          width: 140px;
          height: 40px;
          line-height: 40px;
          font-size: 18px;
          background: #4fa1a4;
          opacity: 1;
          border-radius: 24px;
          text-align: center;
          cursor: pointer;
        }
      }
      .bg-text-right {
        font-family: Source Han Sans CN;
        text-align: right;
        .font-one {
          font-size: 30px;
        }
        .font-two {
          font-size: 22px;
          line-height: 22px;
        }
      }
    }
  }
  //中间
  .main-contain {
    width: 1200px;
    .case-title {
      font-size: 32px;
      text-align: center;
      margin: 43px 0;
    }
    p{
      font-size: 16px;
      text-align: left;
      text-indent: 2em;
      line-height: 30px;
      margin-bottom: 10px;
    }
    .case-img-box{
      display: flex;
      justify-content: space-between;
    }
    .serve-img{
      width: 70%;
      margin-left: -30%;
    }
  }
}
</style>
