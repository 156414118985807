<template>
  <div class="mobile-home">
    <div class="mobile-home-header">
      <img src="../../../assets/mobile_images/mo_hospital_business_bg.jpg" alt="" />
      <!-- <div class="mobile-header-btn">进一步了解</div> -->
    </div>
    <!-- <mobileTab :tabs="tabs" @tabChange="onTabChange"  ref="tabs" ></mobileTab> -->
    <div class="mobile-case-box">
      <div class="case-title">传世般若SPD合作伙伴招商书</div>
      <!-- <img class="w100f" :src="spdImg1" alt=""> -->
      <!-- <h2>客户简介</h2> -->
      <p>
        深圳传世般若科技有限公司(简称传世科技)是国家高新技术企业，定位医药行业流通数字化解决方案服务商，以供应链数字技术为基础，为医疗机构及医药企业提供软硬件一体化解决方案，在武汉、北京、威海、西安分别设有分支机构。
      </p>
      <p>传世科技总经理蘧勇先生，自二十一世纪初就在国外从事医药物流行业，是将国外SPD整体经验与技术系统性引进国内的先行者，在国内多家头部医药公司组织落地了多个成功SPD应用案例；现今联合创办了传世科技，在潜心总结了以往经验之后，重新打造了拥有全部知识产权且更加卓越的SPD全套解决方案和全系列产品，作品著作权包括“传世医院物流整体解决方案”的 (国作登字-2019-I-00817442)；软件著作权包括医院药品SPD、医用物资SPD、医链云(供应商采购平台)等，产品专利包括超高频RFID高值耗材柜、普耗柜、试剂存放柜、配送机器人等系列。</p>
      <p>
        当前国内SPD发展速度较快，根据2021年全国医院SPD项目公开招标不完全汇总显示，2021年全年共有26个省市211家医院公开招标SPD相关项目，《2020年度中国医院综合排行榜》中的百强医院已有53%实施了SPD。在头部医院的带动效应之下，实施SPD已成为智慧化医院数字化转型的基本需求，市场正处于快速发展期。
      </p>
      <p>传世科技诚招全国各地SPD合作伙伴，传世科技愿为合作伙伴提供包括产品开源在内的一站式解决方案，帮助合作伙伴成功销售。</p>
      <p>招商洽谈请联系叶先生：188-0278-9381(微信同号) </p>
      <p></p>
    </div>
  </div>
</template>
<script>
import mobileTitle from '@/components/title/mobile_title.vue'; //标题组件
import mobileTab from '@/components/tab/mobile_tab.vue'; //选项卡组件
import mobileImg from '@/components/img/mobile_img.vue'; //图片组件
export default {
  components: {
    mobileTitle,
    mobileTab,
    mobileImg,
  },
  data() {
    return {
      spdImg1:require('../../../assets/mobile_images/mo_case_spd1.png'),
      spdImg2:require('../../../assets/mobile_images/mo_case_spd2.png'),
      spdImg3:require('../../../assets/mobile_images/mo_case_spd3.png'),
      spdImg4:require('../../../assets/mobile_images/mo_case_spd4.png'),
      currentIndex: 0,
      tabs: [
        { title: 'FBT案例', path: '/case/fbt' },
        { title: 'SPD案例', path: '/case/spd' },
        { title: '高值耗材柜案例', path: '/case/cabinet' },
        { title: '药链云案例', path: '/case/yly' },
      ],
    };
  },
//   mounted(){
//     this.$nextTick(()=>{
//       this.$refs.tabs.currentIndex = 1
//     })
//   },
  methods: {
    onTabChange(item, index) {
    //   this.currentIndex = index;
      // this.$refs.tabs.currentIndex = index
      console.log(index);
    },
  },
};
</script>
<style lang="less" scoped>
.mobile-home {
  margin: 0 auto;
  .mobile-home-header {
    position: relative;
    min-height: 2.6rem;
    img {
      width: 100%;
    }
    .mobile-header-btn {
      position: absolute;
      left: 50%;
      bottom: 1.8rem;
      transform: translateX(-50%);
      width: 2.4rem;
      height: 0.72rem;
      line-height: 0.72rem;
      font-size: 0.28rem;
      text-align: center;
      color: #fff;
      background: #4fa1a4;
      border-radius: 0.04rem;
    }
  }
  .mobile-case-box{
    color: #3D3D3D;
    padding: 0 .4rem;
    box-sizing: border-box;
    .case-title{
      font-size: .4rem;
      text-align: center;
      padding: .6rem 0;
    }
    h2{
      font-size: .32rem;
      text-align: left;
      margin: .46rem 0 .42rem 0;
    }
    p{
      font-size: .28rem;
      line-height: .5rem;
      text-align: left;
      text-indent: 2em;
      margin-bottom: .2rem;
    }
    .mt_026{
      margin-top: .26rem;
    }
  }
}
</style>
